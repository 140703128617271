@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

:root {
	--mainColor: #1E3877;
	--mainColorLight: #5767aa;
	--secondaryColor: #E84A27;
	--textColor: #eee;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
    /* background-color: #000; */
	/* font-family: "Titillium Web", sans-serif; */
}

h3{
	position: relative;
	top: 25%;
	left: 50%;
	transform: translate(-50%, -50%);
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
	z-index: 9999;
	top: 0;
	position: fixed;
	width: 100%;
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

.radio{
	margin-top: 8em;
}

table {
	border-collapse: collapse;
	width: 100%;
	top: 5em;
  }
  th, td {
	padding: 8px;
	text-align: left;
	border-bottom: 1px solid #ddd;
  }

header img{
	height: 40px;
	width: 40px;
	
	position:fixed; 
	top:20px; 
	left:20px; 
}

nav {
	display: flex;
	align-items: center;
	right: 0;
	position: fixed;
}

@media only screen and (max-width: 1324px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: 0;
		right: 0;
		height: 100%;
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
		transform: translateX(100vh);
		z-index: 9999;
	}

	header .responsive_nav {
		transform: none;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
} 

.grid-container {
	display: grid;
	grid-template-columns: auto auto auto;
	background-color: #f8f4f3;
	padding: 10px;
  }
  .grid-item h1{
	background-color: #1E3877;
	font-weight: bold;
	padding: 20px;
	font-size: 30px;
	text-align: center;
	color: white;
	
  }
  .grid-item {
	background-color: #E84A27;
	border: 1px solid rgba(254, 254, 254, 0.8);
	padding: 20px;
	font-size: 30px;
	text-align: center;
	
  }
  .grid-item p{
	font-size: 10px;
	color:white;
	font-weight: bold;
  }